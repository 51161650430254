.react-hot-toast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.35rem;
  padding: 0.5rem 0;
}

.react-hot-toast__message {
}

.react-hot-toast__icon--success {
  color: #22c55e;
}

.react-hot-toast__icon--error {
  color: #ef4444;
}
.react-hot-toast__icon--warning {
  color: #f59e0b;
}

.react-hot-toast__action--dismiss {
  cursor: pointer;
  color: #525252;

  &:hover {
    color: #262626;
  }
}
