.form-label-required:after {
  content: '*';
  color: value($color-plain, redsmart);
}

label {
  font-weight: 500;
  line-height: 15px;
}

button[type='reset'],
button[type='submit'] {
  border-width: 2px;

  &:disabled {
    cursor: not-allowed;
  }

  &[data-loader='true'] {
    cursor: wait;
  }
}
