@import 'tippy.js/dist/tippy.css';

.tippy-box {
  &--timeline {
    color: value($color-basic, darkest);
    background-color: value($color-basic, brightest);
    box-shadow: 0 8px 30px rgba(value($color-shadow, darkerblue), 0.12);
    font-weight: value($font-weight, bold);
    top: 0.5rem;
  }
}

.tippy-box--timeline .tippy-content {
  padding: 0;
}
