/**
 * Font-face generator for all the fonts of the project,
 * will be useful to add different files for each font.
 */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'OpenSans';
  font-path: '../../src/assets/fonts/OpenSans/OpenSans-Light';
  font-weight: value($font-weight, light);
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  font-path: '../../src/assets/fonts/OpenSans/OpenSans-Regular';
  font-weight: value($font-weight, normal);
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  font-path: '../../src/assets/fonts/OpenSans/OpenSans-Semibold';
  font-weight: value($font-weight, semibold);
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  font-path: '../../src/assets/fonts/OpenSans/OpenSans-Bold';
  font-weight: value($font-weight, bold);
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-path: '../../src/assets/fonts/PlusJakartaSans/PlusJakartaSans-Light';
  font-weight: value($font-weight, light);
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-path: '../../src/assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular';
  font-weight: value($font-weight, normal);
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-path: '../../src/assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium';
  font-weight: value($font-weight, medium);
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-path: '../../src/assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold';
  font-weight: value($font-weight, semibold);
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-path: '../../src/assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold';
  font-weight: value($font-weight, bold);
  font-style: normal;
}
