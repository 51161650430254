@import 'bootstrap/dist/css/bootstrap.css';

.card {
  background-color: value($color-basic, brightest);
  border-width: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1.1rem rgba(value($color-basic, darker), 0.06);

  .card-header {
    background-color: value($color-basic, brightest);
    font-weight: value($font-weight, bold);
    border-width: 0;

    &:first-child {
      border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
    }
  }

  .card-body {
    padding-top: 0.5rem;
  }

  &.bg-light .card-header {
    background-color: rgba(0, 0, 0, 0.03);
  }
}
