.offcanvas {
  max-width: 90rem;
  width: 90vw;

  .offcanvas-header {
    padding: 3rem 3rem 0 3rem;

    .offcanvas-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      color: var(--color-primary-palette-accent-blue);
    }
  }

  .offcanvas-body {
    padding: 1rem 3rem;
  }

  @media screen and (min-width: value($media, l)) {
    width: 100vw;
  }
}
