/**
 * Define global styles for the application,
 * usually html and body content.
 */

html,
body {
  color: value($color-basic, darkest);
  background-color: value($color-basic, semibrighter);
  font-family: var(--font-jakarta);
  font-size: calc($default-font-size * 0.95);
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  @media screen and (min-width: value($media, m)) {
    font-size: $default-font-size;
  }
}

a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}

$scrollbar-track-color: transparent;
$scrollbar-thumb-color: value($color-basic, normal);
$scrollbar-width: rem(16px);

div {
  ::-webkit-scrollbar {
    background: $scrollbar-track-color;
    height: $scrollbar-width;
    width: $scrollbar-width;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $scrollbar-width;
    border: 3px solid transparent;
    background-clip: content-box;

    &:hover {
      background-color: value($color-basic, dark);
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--color-primary-palette-white);
  font-family: var(--font-outfit);

  @media screen and (min-width: value($media, xs)) {
    margin-left: $navbar-size;
    width: calc(100% - $navbar-size);
  }
}

:root {
  --animate-fade-in-duration: 300ms;
  --animate-fade-out-duration: 300ms;
}

.page__container {
  margin-top: $header-height;
}

dialog {
  border: none;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  background-color: #02061733;
}

@media (prefers-reduced-motion: no-preference) {
  dialog[open] {
    animation-name: animation-dialog;
    animation-duration: 350ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  dialog[open]::backdrop {
    animation-name: animation-dialog-backdrop;
    animation-duration: 350ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@keyframes animation-dialog {
  0% {
    opacity: 0;
    transform: scale(0) translate(0%, 100%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0%, 0%);
  }
}

@keyframes animation-dialog-backdrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
