/**
 * Helpers with typical values that you need,
 * in the app, by default we usually use
 * Bootstrap utilities but, you can define more here.
 *
 * How to use:
 * <div class="u-align-center">
 */

@import './mixins/map-utilities';

.u {
  &-align {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }
  }

  &-font-weight-normal {
    font-weight: normal !important;
  }

  @mixin map-utilities color, basic, $color-basic;
}

@keyframes pulse {
  0%,
  100% {
    background-color: rgba(var(--pulse-background-color), 1);
  }
  50% {
    background-color: rgba(var(--pulse-background-color), 0.5);
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  animation-duration: var(--animate-fade-in-duration);
}

.fade-out {
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: var(--animate-fade-out-duration);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
