/* stylelint-disable indentation */
@import '../../styles/base/mixins/bootstrap-theme.css';

/* Migrate incrementally from pre/post-processor vars to native vars */
:root {
  --color-ux-secondary-5: #2e3034;

  --color-primary-palette-white: #ffffff;
  --color-primary-palette-black: #0d0e0f;

  --color-primary-palette-grey-00: #fafafc;
  --color-primary-palette-grey-01: #f2f3f7;
  --color-primary-palette-grey-02: #e9eaf2;
  --color-primary-palette-grey-03: #dfe1ec;
  --color-primary-palette-grey-04: #8e8f95;
  --color-primary-palette-grey-05: #6a6f74;
  --color-primary-palette-grey-06: #4a4a4a;
  --color-primary-palette-grey-07: #383838;

  --color-secondary-palette-grey-01: #fafafb;

  --color-primary-palette-accent-blue: #2f69ff;
  --color-primary-palette-accent-blue-pale: #f5f8ff;
  --color-primary-palette-accent-light: #5282ff;
  --color-primary-palette-accent-light-2: #dce6ff;
  --color-primary-palette-accent-dark: #0043f0;

  --color-functional-palette-information-blue: #00b5f1;
  --color-functional-palette-information-blue-pale: #e3f8ff;
  --color-functional-palette-information-blue-dark: #008ebd;

  --color-functional-palette-success-green: #3abaaf;
  --color-functional-palette-success-green-pale: #d8f1ef;
  --color-functional-palette-success-green-dark: #257770;

  --color-functional-palette-warning-yellow-light: #fddd8c;
  --color-functional-palette-warning-yellow-dark: #a6842a;

  --color-functional-palette-alert-red: #f33859;
  --color-functional-palette-alert-red-pale: #fde1e6;
  --color-functional-palette-alert-red-light: #f99bac;

  --color-status-red: #df4661;

  --color-unit-palette-unit5: #7fd18d;

  --color-area-palette-mobile: #ff7aa4;

  --font-outfit: 'Outfit', ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  --font-montserrat: 'Montserrat', ui-serif, sans-serif;
  --font-jakarta: 'PlusJakartaSans', ui-serif, sans-serif;

  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;
  --screen-3xl: 1920px;
}

$default-font-size: 14px;
$header-height: 5.5rem;

$color-basic: (
  brightest: #ffffff,
  semibrighter: #fafafa,
  brighter: #f6f7f9,
  semibright: #eaeef2,
  bright: #dfe6ec,
  normal: #c6d1dc,
  dark: #868a93,
  darker: #676d7c,
  semidarker: #4a4a4a,
  darkest: #100f0d
);

$color-grey: (
  semibrighter: #fafafc,
  brighter: #f2f3f7,
  semibright: #e9eaf2,
  bright: #dfe1ec,
  normal: #8e8f95,
  dark: #6a6f74,
  darker: #4a4a4a,
  semidarker: #383838,
  darkest: #0d0e0f
);

$color-plain: (
  accentbluepale: #f5f8ff,
  accentblue: #2f69ff,
  accentbluelight: #5282ff,
  accentbluelight2: #dce6ff,
  accentbluedark: #0043f0,
  bluewave: #0f72e8,
  bluewavealt: #0056b3,
  purplesidra: #8b5de1,
  purplesidraalt: #5323af,
  redsmart: #df4661,
  redsmartalt: #a50924,
  greensalem: #059b2c,
  darkorangine: #c95e00,
  coraline: #c9643e,
  olivegreen: #059b6b,
  aquamarinealt: #036a49
);

$color-forecast: (
  yellow: #fffac9,
  sky: #dfedff,
  purple: #f3ecff,
  pink: #ffe9fa,
  teal: #e9fffb,
  cyan: #d4f5fc,
  indigo: #ebf0ff,
  amber: #fde1e6,
  slate: #e0e0e0,
  white: #ffffff
);

$color-states: (
  error: #db0b0b,
  locked: #f33859
);

$color-alert: (
  redpale: #fde1e6,
  redlight: #f33859,
  reddark: #c72e49
);

$color-shadow: (
  darkerblue: #363e57
);

$time: (
  slow: 0.5s,
  normal: 0.35s,
  fast: 0.15s
);

$font-weight: (
  light: 200,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800
);

$font-size: (
  xxs: 0.68rem,
  xs: 0.75rem,
  s: 0.85rem,
  m: 1rem,
  l: 1.15rem,
  xl: 1.25rem,
  xxl: 1.5rem
);

$font-family: (
  primary: 'Outfit',
  secondary: 'PlusJakartaSans'
);

$font-color: (
  newblue: #2f69ff,
  newblack: #0d0e0f,
  newgrey: #8e8f95
);

$media: (
  xxs: 360px,
  xs: 640px,
  s: 768px,
  m: 1024px,
  l: 1280px,
  xl: 1440px
);

$z: (
  xs: 1,
  s: 10,
  m: 20,
  l: 30,
  xl: 50,
  xxl: 100
);

$radius: (
  xs: 2px,
  s: 4px,
  m: 7px
);

$navbar-size: 3.5rem;
$forecast-actions-padding: 0.2rem 0.5rem 0.3rem 0.5rem;
@define-mixin map-colors ##prop, ##map, ##extra {
  @each ##key, ##value in entries(##map) {
    &--##(key)##(extra) {
      ##(prop): ##value !important;
    }
  }
}

.nav-bar {
  $size-small: $navbar-size;
  $size-big: 15rem;
  $bar-spacing: 0.5vw;
  $icon-size: calc($size-small * 0.5);
  $chevron-margin: 0.5rem;

  background-color: value($color-basic, brightest);
  border-right-style: solid;
  border-right-color: value($color-basic, bright);
  border-right-width: 0.1rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3rem;
  height: 100vh;
  width: 0;
  top: 0px;
  z-index: value($z, xl);
  transition: all value($time, normal);
  overflow-x: hidden;
  overflow-y: auto;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 0;
    height: 100%;
    padding: 2rem 0 $bar-spacing 0;
    transition: all value($time, normal);
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 0 rgba(value($color-basic, darker), 0);
    touch-action: none;

    @media screen and (min-width: value($media, xs)) {
      width: $size-small;
    }
  }

  @media screen and (min-width: value($media, xs)) {
    width: $size-small;
  }

  &.is-open {
    width: $size-big;
    box-shadow: 0.5rem 0 1.1rem rgba(value($color-basic, darker), 0.06);
  }

  &.is-open &__container {
    width: $size-big;
  }

  &__chevron {
    $size: 1.25rem;

    color: value($color-basic, darkest);
    cursor: pointer;
    height: $size;
    width: $size;
    margin: auto 0 $chevron-margin;
    transition: transform value($time, normal), color value($time, fast);

    &:hover {
      color: value($color-basic, darker);
    }
  }

  &.is-open &__chevron {
    transform: rotate(180deg);
    align-self: flex-end;
    margin-right: $chevron-margin;
    position: initial;
  }

  &__hamburger {
    $size: 2rem;
    $margin: 2vw;

    position: fixed;
    bottom: $margin;
    left: $margin;
    cursor: pointer;
    height: $size;
    width: $size;
    transition: left value($time, normal);
    background-color: value($color-basic, brighter);
    color: value($color-basic, darker);
    border-radius: 50%;
    padding: 0.5rem;

    @media screen and (min-width: value($media, xs)) {
      left: -100vw;
    }
  }

  &.is-open &__hamburger {
    left: -100vw;
  }

  &__link {
    display: flex;
    margin-right: 4rem;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    padding: 1.25rem 0;
    padding-left: calc($size-small * 0.15);
    position: relative;
    width: 100%;
    border-left-color: value($color-basic, brightest);
    border-left-style: solid;
    border-left-width: 0.4rem;

    @mixin map-colors color, $color-basic;

    &:hover {
      background-color: value($color-plain, accentbluepale);
      border-left-color: value($color-plain, accentbluepale);
      border-left-style: solid;
      border-left-width: 0.4rem;
    }
  }

  &__is-active {
    background-color: value($color-basic, semibright);
    border-left-style: solid;
    border-left-color: value($color-plain, bluewave);
    border-left-width: 0.4rem;
    pointer-events: auto;
  }

  &__icon {
    height: $icon-size;
    width: $icon-size;
    position: absolute;
    transition: opacity value($time, fast);

    &--solid {
      opacity: 0;
      color: value($color-plain, bluewave);
    }

    &--admin {
      $icon-admin-size: 0.8rem;

      color: value($color-plain, bluewavealt);
      bottom: -0.3rem;
      left: 2.2rem;
      height: $icon-admin-size;
      width: $icon-admin-size;
    }
  }

  &__link:hover &__icon {
    opacity: 0;

    &--solid {
      opacity: 1;
    }
  }

  &__name {
    color: value($color-basic, darkest);
    padding-left: $size-small;
    font-weight: value($font-weight, semibold);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: opacity value($time, fast);

    &--solid {
      opacity: 0;
      color: value($color-plain, bluewave);
    }

    &--admin {
      color: value($color-plain, bluewavealt);
    }
  }

  &__link:hover &__name {
    color: value($color-plain, bluewavealt);
  }

  &__link.is-active &__icon {
    color: value($color-plain, bluewave);
  }

  &__link.is-active &__name {
    color: value($color-plain, bluewave);
  }

  &__link.allow-click {
    pointer-events: auto;
  }

  &__user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    padding-left: calc($size-small * 0.15);
    position: relative;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1.2vw;
  }

  &__username {
    color: value($color-basic, dark);
  }
}
